<template>
  <div>
    <b-row class="match-height">
      <b-col>
        <b-card
          text-variant="center"
          class="card card-congratulations"
        >
          <b-avatar
            :src="require('@/assets/images/avatars/13-small.png')"
            size="70"
            class="shadow mb-2"
          >
          </b-avatar>
          <h3 class="mb-1 mt-50 text-white">
            Halo {{ userData.name }} 🖐️
          </h3>
        </b-card>
      </b-col>
      <b-col
        lg="8"
        cols="12"
      >
        <b-card
          v-if="data"
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>Statistics</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Updated 1 month ago
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in data"
                :key="item.icon"
                xl="3"
                sm="6"
                :class="item.customClass"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      :variant="item.color"
                    >
                      <feather-icon
                        size="24"
                        :icon="item.icon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.title }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          statistic="86%"
          statistic-title="SD"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          color="success"
          statistic="1.2gb"
          statistic-title="SMP"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          statistic="0.1%"
          statistic-title="SMA"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          color="warning"
          statistic="13"
          statistic-title="SBMPTN"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="12"
        cols="12"
      >
        <b-card
          v-if="data"
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>Jumlah Siswa Mengerjakan Ujian</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Data Bulan Lalu
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                xl="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="24"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      100
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Siswa SD
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                xl="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-success"
                    >
                      <feather-icon
                        size="24"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      100
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Siswa SMP
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                xl="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-danger"
                    >
                      <feather-icon
                        size="24"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      100
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Siswa SMA
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                xl="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-warning"
                    >
                      <feather-icon
                        size="24"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      100
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Siswa SBMPTN
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCard,
    BCardText,
    StatisticCardHorizontal,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  data() {
    return {
      data: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Siswa',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        // Add more items as needed
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>

<style>

</style>
